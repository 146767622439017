import router from './router'
import store from './store'

//to：进入到哪个路由去
//from：从哪个路由离开
//next：进入下一步
router.beforeEach(async (to, from, next) => {

  // console.log(store.getters.getBlog.data.siteName)
  // 调用 Vuex store 中的 action
  await store.dispatch('getSiteHomeStore')
  document.title = store.getters.getBlog.data.siteName
  next();
  
});



