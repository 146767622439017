import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'), // 导入方式
  },{
    path: '/tags',
    name: 'tags',
    component: () => import('../views/tag/Tag.vue'), // 导入方式
    
  },{
    path: '/tags/:tagName',
    name: 'tags',
    component: () => import('../views/tag/TagList.vue'), // 导入方式
    props: true,  // 将路由参数作为组件属性传递
    
  },{
    path: '/categorys',
    name: 'categorys',
    component: () => import('../views/category/Category.vue'), // 导入方式
    
  },{
    path: '/categorys/:categoryName',
    name: 'categorys',
    component: () => import('../views/category/CategoryList.vue'), // 导入方式
    props: true,  // 将路由参数作为组件属性传递
    
  },{
    path: '/archives',
    name: 'archives',
    component: () => import('../views/archive/ArchiveList.vue'), // 导入方式
    
  },{
    path: '/page/:field',
    name: 'page',
    component: () => import('../views/page/Page.vue'), // 导入方式
    
  },{
    path: '/friend',
    name: 'friend',
    component: () => import('../views/friend/Friend.vue'), // 导入方式
  },{
    // 放到最后，不然会出错
    path: '/:articleUri',
    name: 'post',
    component: () => import('../views/post/Post.vue'), // 导入方式
  }
  


]

// // 假设的检查登录状态的函数
// function isLoggedIn() {
//   return !!localStorage.getItem('blog-sa-token'); // 以token存在与否作为登录状态的简单示例
// }

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // 这个路由需要认证，请检查是否已登录
//     // 如果没有登录，则重定向到登录页面
//     if (!isLoggedIn()) {
//       next({
//         path: '/',
//         query: { redirect: to.fullPath } // 将目标位置作为参数传递，以便登录后可以重定向回来
//       });
//     } else {
//       next(); // 已经登录，继续请求原本的路由
//     }
//   } else {
//     next(); // 确保一定要调用next()
//   }
// });

const router = new VueRouter({
  mode: 'history', // 去除URL上的#号
  routes
})

export default router
