import Vue from 'vue'
import Vuex from 'vuex'
import { getSiteHome } from '@/api/home'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    // 获取站点信息
    blog: {}
  },
  getters: {
    getBlog(state) {
      return state.blog
    },
  },
  mutations: {

    setBlog(state, data) {
      state.blog = data;
    },
  },
  actions: {
    // 获取站点信息
    getSiteHomeStore({ commit }) {
      return getSiteHome()
        .then((response) => {
          commit('setBlog', response);
        }).catch(error => {
          console.error(error);
          throw error;
        });
    },
  },
  modules: {
  },
  plugins: [ vuexLocal.plugin]

})
