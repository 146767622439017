import request from '@/utils/request'

/**
 * 文章列表请求
 * @param {int,int} query;
 * @returns 返回文章列表
 */
export function listHomeArticle(page) {
  return request({
    url: '/api/desk/home/list/'+ page,
    method: 'get'
  })
}

/**
 * 首页基本信息获取
 * @returns 返回首页基本信息
 */
export function getSiteHome() {
  return request({
    url: '/api/desk/home/site/',
    method: 'get'
  })
}

/**
 * 热度文章列表请求
 * @returns 返回文章列表
 */
export function listHotArticle() {
  return request({
    url: '/api/home/hot',
    method: 'get'
  })
}

/**
 * 站点访问信息
 */
export function getInfoSite() {
  return request({
    url: '/api/home/info',
    method: 'get'
  })
}